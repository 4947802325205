/*const GET_CURRENT_USER = 'GET_CURRENT_USER';
const GET_CURRENT_USER_LOADING = 'GET_CURRENT_USER_LOADING';

export{
  GET_CURRENT_USER,
  GET_CURRENT_USER_LOADING
}*/

const GET_IS_AUTHENTICATED = 'GET_IS_AUTHENTICATED';
const GET_AUTH_STATUS = 'GET_AUTH_STATUS';
const GET_ROLE = 'GET_ROLE';
const GET_FILTER = 'GET_FILTER';
const GET_LANG = 'GET_LANG';
const GET_IMAGE_PAGE_NO = 'GET_IMAGE_PAGE_NO';
const GET_IMAGE_NEXT_BUTTON_ACTIVE = 'GET_IMAGE_NEXT_BUTTON_ACTIVE';
const GET_IMAGE_PREV_BUTTON_ACTIVE = 'GET_IMAGE_PREV_BUTTON_ACTIVE';
const GET_COOKIES_STATUS = 'GET_COOKIES_STATUS';

export{
  GET_IS_AUTHENTICATED,
  GET_AUTH_STATUS,
  GET_ROLE,
  GET_FILTER,
  GET_LANG,
  GET_IMAGE_PAGE_NO,
  GET_IMAGE_NEXT_BUTTON_ACTIVE,
  GET_IMAGE_PREV_BUTTON_ACTIVE,
  GET_COOKIES_STATUS
}

