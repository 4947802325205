const SET_AUTH_REQUEST = 'SET_AUTH_REQUEST';
const SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS';
const SET_AUTH_SUCCESS_CURRENT_USER = 'SET_AUTH_SUCCESS_CURRENT_USER';
const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
const SET_AUTH_LOGOUT = 'SET_AUTH_LOGOUT';
const SET_FILTER = 'SET_FILTER';
const SET_FILTER_ALL = 'SET_FILTER_ALL';
const SET_LANG = 'SET_LANG';
const SET_PICTURE_NEXT_PAGE = 'SET_PICTURE_NEXT_PAGE';
const SET_PICTURE_PREV_PAGE = 'SET_PICTURE_PREV_PAGE';
const SET_PICTURE_NEXT_BUTTON_ACTIVE = 'SET_PICTURE_NEXT_BUTTON_ACTIVE';
const SET_PICTURE_PREV_BUTTON_ACTIVE = 'SET_PICTURE_PREV_BUTTON_ACTIVE';
const SET_PICTURE_TOTAL_PAGES = 'SET_PICTURE_TOTAL_PAGES';
const SET_FIRST_PAGE = 'SET_FIRST_PAGE';
const SET_COOKIE_MESSAGE = 'SET_COOKIE_MESSAGE';

export{
  SET_AUTH_REQUEST,
  SET_AUTH_SUCCESS,
  SET_AUTH_SUCCESS_CURRENT_USER,
  SET_AUTH_ERROR,
  SET_AUTH_LOGOUT,
  SET_FILTER,
  SET_FILTER_ALL,
  SET_LANG,
  SET_PICTURE_NEXT_PAGE,
  SET_PICTURE_PREV_PAGE,
  SET_PICTURE_NEXT_BUTTON_ACTIVE,
  SET_PICTURE_PREV_BUTTON_ACTIVE,
  SET_PICTURE_TOTAL_PAGES,
  SET_FIRST_PAGE,
  SET_COOKIE_MESSAGE
} 

/*
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const SET_CURRENT_USER_LOADING = 'SET_CURRENT_USER_LOADING';

export{
  SET_CURRENT_USER,
  SET_CURRENT_USER_LOADING
}*/