const AUTH_REQUEST = 'AUTH_REQUEST';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
const FILTER = 'FILTER';
const FILTER_ALL = 'FILTER_ALL';
const LANG = 'LANG';
const PICTURE_NEXT_PAGE = 'PICTURE_NEXT_PAGE';
const PICTURE_PREV_PAGE = 'PICTURE_PREV_PAGE';
const COOKIE_MESSAGE = 'COOKIE_MESSAGE';

export{
  AUTH_REQUEST,
  AUTH_LOGOUT,
  FETCH_CURRENT_USER,
  FILTER,
  FILTER_ALL,
  LANG,
  PICTURE_NEXT_PAGE,
  PICTURE_PREV_PAGE,
  COOKIE_MESSAGE
}
/*
const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

export{
  FETCH_CURRENT_USER,
  LOGIN,
  LOGOUT
} */